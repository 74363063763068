<template>
  <div class="photoWrapper">
    <div class="facePreview" id="facePreview"
      style="display: flex; justify-content:center;align-items: center; background-repeat: no-repeat;" :style="{
        backgroundSize: faceParam.imageWidth+'px'+' '+faceParam.imageHeight+'px',
        backgroundImage: 'url(' + facePreviewSrc + ')',
        backgroundPosition: `${faceParam.left}px ${faceParam.top}px`,
        width: canShowSearchedFace ? faceParam.photoPreviewWidth+'px' :  width+'px',
        height: canShowSearchedFace ? faceParam.photoPreviewHeight+'px' : width+'px'
      } ">
    </div>
  </div>

</template>

<script>
import imageMixin from "@/mixins/imageMixin";
import env from "../../configs/env.json";

export default {
  name: "searchedFace",
  mixins: [
    imageMixin
  ],
  props: {
    child: {
      default: Object,
      required: false
    },
    parent: {
      default: Object,
      required: false,
    },
    index: {
      default: null,
      required: false
    },
    face_id: {
      default: null,
      required: false
    },
    width: {
      default: null,
      requered: false
    }
  },
  data() {
    return {
      facePreviewSrc: env.prefix + "files/assets/images/social/profile.jpg",
      src: undefined,
      facesLoaded: false,
      faceParam: {},
      canShowSearchedFace: false,
      payload: undefined,
    }
  },
  watch: {
    src() {
      if (typeof this.child.value !== 'undefined') {
        this.setImageParam(this.src, this.child)
      }
    }
  },
  methods: {
    setImageParam(parentImage, childImage) {
      this.facePreviewSrc = parentImage;
      const index = 0;
      // const sizeCoef = this.index !== null ? 1.43 : 1;
      const resolveObject = childImage;
      // if(typeof resolveObject.face_select.width === 'undefined') {
      //   let i = new Image(); 
      //   i.src = this.src; 
      //   i.onload = ()=>{
      //     resolveObject.face_select.width = i.width;
      //     resolveObject.face_select.height = i.height
      //   };
      // }
      if (typeof resolveObject.face_select !== 'undefined') {
        const face = resolveObject.face_select.face_boxes[index];
        let faceWidthCoef = this.renderedWidth / face.width;
        let faceHeightCoef = this.renderedWidth / face.height;
        this.faceParam = {
          face,
          faceWidthCoef,
          width: resolveObject.face_select.width,
          faceWidth: face.width,
          left: -face.left * faceWidthCoef,
          top: -face.top * faceHeightCoef,
          photoPreviewWidth: resolveObject.face_select.face_boxes[index].width * faceWidthCoef,
          photoPreviewHeight: resolveObject.face_select.face_boxes[index].width * faceHeightCoef,
          imageWidth: resolveObject.face_select.width * faceWidthCoef,
          imageHeight: resolveObject.face_select.height * faceHeightCoef
        }
        this.canShowSearchedFace = true;
      } else {
        this.canShowSearchedFace = false;
      }
    },
  },
  computed: {
    renderedWidth() {
      return this.width || 128;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof this.child.value !== 'undefined') {
        if (!this.fromSearchListItem || this.fromSearchListChild) {
          this.payload = this.child.value;
          this.loadImg(false)
        }
      }
    })
  }
}
</script>