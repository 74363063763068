import { mapGetters } from "vuex";
import imageAxios from "../configs/iamgeAxios";

const DB_NAME = 'gds'
const STORAGE_NAME = 'images'
const DB_VERSION = 3
let DB
export default {
    computed: {
        ...mapGetters('auth', ['token'])
    },
    methods: {
         getDb() {
            return new Promise((resolve, reject) => {
                if (DB) {
                    return resolve(DB)
                }
                const request = window.indexedDB.open(DB_NAME, DB_VERSION)
                request.onerror = e => {
                    console.error('Error opening db', e)
                    reject('Error')
                }
                request.onsuccess = e => {
                    DB = e.target.result
                    resolve(DB)
                }
                request.onupgradeneeded = e => {
                    let db = e.target.result
                    db.createObjectStore(STORAGE_NAME)
                }
            })
        },


        async saveImage(image, payload) {
            let db = await this.getDb()
            return new Promise(resolve => {
                let trans = db.transaction([STORAGE_NAME], 'readwrite')
                trans.oncomplete = () => {
                    resolve()
                }
                let store = trans.objectStore(STORAGE_NAME)
                store.add({data: image }, payload)
                // db.close()
            })
        },


       async loadImg(firstLoad) {
            let result = await this.checkCache(this.payload);
            if (result.isCached){
                if (this.FaceSearchGlobal){
                    this.srcMini = result.image.data;
                } else {                   
                    this.src = result.image.data;
                }
                this.loaded = true;
                this.fullLoad = true;
                if (!firstLoad){
                    this.$store.dispatch('images/addImage', {
                        key: this.payload,
                        value: ''
                    });
                }
            } else {
                let axiosSettings;
                // if (firstLoad) {
                //     axiosSettings = {
                //         method: "get",
                //         responseType: 'arraybuffer',
                //         params: {
                //             size: '128',
                //             thumbnail: true
                //         }
                //     }
                // } else {
                    axiosSettings = {
                        method: "get",
                        responseType: 'arraybuffer',
                        headers: {
                            'Authorization': this.token 
                        }
                    }
                // }
                return new Promise((resolve, reject) => {
                    // const imAxios = imageAxios(localStorage.getItem('token'))
                    imageAxios(this.payload, axiosSettings,)
                        .then((resp) => {
                            let blob = new Blob([resp.data], {type: "image/jpg"})
                            let reader = new window.FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = () => {
                                this.src = reader.result;
                                this.loaded = true;
                                if (!firstLoad) {
                                    this.saveImage(reader.result, this.payload)
                                    this.fullLoad = true;
                                } 
                                // else {
                                //     this.srcMini = reader.result;
                                //     this.saveImage(reader.result, 'mini_'+this.payload)
                                // }
                                this.$store.dispatch('images/addImage', {
                                    key: this.payload,
                                    value: ''
                                });
                            }
                        })
                        .catch(err => {
                            reject(err)
                        })
                })
            }
        },


        async checkCache(payload){
            let db = await this.getDb()
            let transaction = db.transaction([STORAGE_NAME], "readonly");
            let objectStore = transaction.objectStore(STORAGE_NAME);
            let request
            if (this.FaceSearchGlobal){
                 request = objectStore.get('mini_'+payload)
            } else {
                 request = objectStore.get(payload);
            }

            let result

            return new Promise(resolve => {

                request.onerror =  function (event) {
                    console.error('error' + event)
                };

                request.onsuccess = function (event) {
                    if (typeof event.target.result === 'undefined') {
                        result = {
                            isCached: false
                        }
                        resolve(result)
                    } else {
                        result = {
                            isCached: true,
                            image: event.target.result
                        }
                        resolve(result)
                    }
                    // db.close()
                }

            })
        },

        async loadNonComponentImage(link) {
            let result = await this.checkCache(link);
            if (result.isCached){
                return result.image.data;
            }
        }
    }
}

// import Dexie from 'dexie';
// import imageAxios from '../configs/iamgeAxios';

// const DB_NAME = 'gds';
// const STORAGE_NAME = 'images';
// const DB_VERSION = 1;

// const db = new Dexie(DB_NAME);
// db.version(DB_VERSION).stores({
//     [STORAGE_NAME]: '++id, payload, data',
// });

// export default {
//     methods: {
//         async saveImage(image, payload) {
//             await db.open()
//             await db.images.put({ payload, data: image });
//         },

//         async loadImg(firstLoad) {
//             const result = await this.checkCache(this.payload);
//             if (result.isCached) {
//                 if (this.FaceSearchGlobal) {
//                     this.srcMini = result.image.data;
//                 } else {
//                     this.src = result.image.data;
//                 }
//                 this.loaded = true;
//                 this.fullLoad = true;
//                 if (!firstLoad) {
//                     await this.saveImage(result.image.data, this.payload);
//                 }
//             } else {
//                 let axiosSettings;
//                 axiosSettings = {
//                     method: 'get',
//                     responseType: 'arraybuffer',
//                 };

//                 try {
//                     const imAxios = imageAxios(localStorage.getItem('token'));
//                     const resp = await imAxios(this.payload, axiosSettings);
//                     const blob = new Blob([resp.data], { type: 'image/jpg' });
//                     const reader = new FileReader();
//                     reader.readAsDataURL(blob);
//                     reader.onload = async () => {
//                         this.src = reader.result;
//                         this.loaded = true;
//                         if (!firstLoad) {
//                             await this.saveImage(reader.result, this.payload);
//                             this.fullLoad = true;
//                         } else {
//                             this.srcMini = reader.result;
//                             await this.saveImage(reader.result, 'mini_' + this.payload);
//                         }
//                     };
//                 } catch (err) {
//                     console.error(err);
//                     throw err;
//                 }
//             }
//         },

//         async checkCache(payload) {
//             await db.open()
//             const result = await db.images.where('payload').equals(payload).first();
//             if (result === undefined) {
//                 return { isCached: false };
//             } else {
//                 return { isCached: true, image: result };
//             }
//         },

//         async loadNonComponentImage(link) {
//             const result = await this.checkCache(link);
//             if (result.isCached) {
//                 return result.image.data;
//             }
//         },
//     },
// };

