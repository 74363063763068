<template>
  <div
      :style="
      comment.level === 0
      ? { padding: '20px', marginBottom: '20px', marginTop: '20px', paddingTop: '20px', background: '#f1f1f1', }
      : {paddingLeft: '40px', paddingTop: '20px'}"
  >
    <div style="display: flex; align-items: center;" :style="comment.level > 0 ? {paddingTop: '20px', borderTop: '4px solid rgb(255, 255, 255)'} : {}">
      <div v-if="comment.level > 0" style="margin-right: 20px;">
        <svg v-if="comment.level === 1" data-v-23ad7aee="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="reply fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-reply-fill h5 m-t-5 b-icon bi"><g data-v-23ad7aee="" transform="translate(8 8) scale(-1 -1) translate(-8 -8)"><path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"></path></g></svg>
        <svg v-if="comment.level > 1" data-v-23ad7aee="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="reply all fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-reply-all-fill h5 m-t-5 b-icon bi"><g data-v-23ad7aee="" transform="translate(8 8) scale(-1 -1) translate(-8 -8)"><path d="M8.021 11.9 3.453 8.62a.719.719 0 0 1 0-1.238L8.021 4.1a.716.716 0 0 1 1.079.619V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"></path><path d="M5.232 4.293a.5.5 0 0 1-.106.7L1.114 7.945a.5.5 0 0 1-.042.028.147.147 0 0 0 0 .252.503.503 0 0 1 .042.028l4.012 2.954a.5.5 0 1 1-.593.805L.539 9.073a1.147 1.147 0 0 1 0-1.946l3.994-2.94a.5.5 0 0 1 .699.106z"></path></g></svg>
      </div>
      <div>
        <div style="display: flex; flex-wrap: wrap; gap: 10px;">
          <div
              v-for="(data, index) in comment.data.data"
              :key="index"
              style="display: flex; width: 42%; padding: 5px; align-items: center; justify-content: space-between;"
          >
            <div style="font-weight: bold;">
              <span v-if="data.param === 'social_url'">{{ $t(`comment_search.link`) }}</span>
              <span v-else>{{ $t(`params.${data.param}`) }}</span>
            </div>
            <div>
              {{ data.value }}
            </div>
          </div>
          <div style="display: flex; width: 42%; padding: 5px; align-items: center; justify-content: space-between;">
            <div style="font-weight: bold;">{{ $t('comment_search.date') }}</div>
            <div>{{ moment.unix(comment.date).format('DD.MM.YYYY HH:mm') }}</div>
          </div>
        </div>

        <div style="display: flex; padding: 5px;">
          <div><strong>{{ $t('comment_search.contentType.comment') }}</strong></div>
          <div>{{ comment.text }}</div>
        </div>
      </div>
    </div>

    <CommentTree
        v-for="child in comment.children"
        :key="child.id"
        :comment="child"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CommentTree",
  computed: {
    moment() {
      return moment
    }
  },

  props: {
    comment: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style scoped>

</style>