<template>
    <div class="photoWrapper">
        <div class="facePreview" id="facePreview"
            :style="{
                backgroundSize: `${bgwidth}px ${bgheight}px`,
                backgroundImage: 'url(' + facePreviewSrc + ')',
                backgroundPosition: `${left}px ${top}px`,
                width: propWidth ?  `${propWidth}px` : `${width}px`,
                height: propWidth ?  `${propWidth}px` : `${height}px`,
            }">
        </div>
    </div>

</template>
<script>
import imageMixin from "@/mixins/imageMixin";
import env from "../../configs/env.json";
import _ from 'underscore';
    export default {
        name: "searchedPortrait",
        props: ['portrait', 'propWidth',],
        data() {
            return {
                facePreviewSrc: env.prefix + "files/assets/images/social/profile.jpg",
                width: 128,
                height: 128,
                bgwidth: 128,
                bgheight: 128,
                src: null,
                left: 0,
                top: 0
            }
        },
        mixins: [
            imageMixin
        ],
        methods: {
            setImage(val) {
                this.facePreviewSrc = val
            },
            getRawImage(imageUrl) {
                this.payload = imageUrl
                this.loadImg(false)
            },
            setParams(faceParams, portrait) {
              let bgCoef;
              if (typeof this.propWidth === 'undefined' ){
                bgCoef = 128 / faceParams.width
              }  else {
                bgCoef = this.propWidth / faceParams.width
              }
                this.bgwidth = portrait.width * bgCoef
                this.bgheight = portrait.height * bgCoef
                this.left =  -faceParams.left * bgCoef
                this.top = -faceParams.top * bgCoef
            }
        },
        watch: {
            src(val) {
                if(val) {
                    this.setImage(val)
                }
            },
        },
        mounted() {
            
        }
    }
</script>
<style>
    .facePreview {
        display: flex;
        justify-content:center;
        align-items: center;
        background-repeat: no-repeat;
    }
</style>