import { Result } from "@/models/result";
export default {
    methods: {
        async openResult(result) {
            if ((!result.loaded || result.error ) && result.name !== "many faces" ) {
                return false;
            } else {
                const res = await Result.getResultByKey(result.keyVal)
                if (result.param === 'image') {
                    if (typeof result.face_select === 'undefined'){
                        await this.$router.push({name: 'Photo', params: {id: res.id}})
                    } else if (result.face_select.length > 1){
                        if (result.loaded && result.name === "many faces" && !this.showLoader ){
                            this.isPhotoSegments = true;
                            if (typeof this.photo !=='undefined' && this.photo.face_select === "undefined" ){
                                this.searchPhoto();
                            }
                        }
                    }
                } else if (result.param === 'geo_search_telegram') {
                    await this.$router.push({name: 'Location', params: {id: res.id}})
                } else {
                    await this.$router.push({name: 'Result', params: {id: res.id}})
                }
            }


        }
    }
}