<template>
  <div style="max-width: 1200px; margin: 0 auto;">
    <div class="search__title">
      <h1>
        {{ $t('search.search_results') }} -
        <span v-if="parentSearch.source_locales" style="text-transform: lowercase;">{{ parentSearch.source_locales[selectedLocale()] }}</span>
      </h1>
    </div>

    <div v-for="item in parentSearch.search_results">
      <div style="display: flex; align-items: flex-start;">
       <div class="js-show-first-photo" :style="`width:${photoSize(item)}px; height:${((item.data.length + 1)* 52) - 3}px`">
         <div v-if="item.photos.length">
           <image-profile
               style="cursor: default;"
               :size="`${photoSize(item)}px`"
               :payload="item.photos[0].image"
           />
         </div>
         <div class="js-btn-show-more" v-if="item.photos.length > 1">
           {{ $t('result.show_more') }}
         </div>
       </div>
        <div style="width: 300px; background-color: rgb(241 241 241); border-bottom-right-radius: 3px; border-bottom-left-radius: 3px; border-top: 1px solid #f4f4f4;">
          <div v-for="data in item.data" :key="item.id" style="height: 48px; padding: 0 15px; display: flex; align-items: center; justify-content: space-between; border-bottom: 4px solid #ffffff;">
            <div>{{ $t(`params.${data.param}`) }}</div>
            <div style="padding: 3px 20px;">{{ data.value }}</div>
          </div>
          <div style="height: 48px; padding: 0 15px; display: flex; justify-content: space-between; align-items: center; border-bottom: 4px solid #ffffff;">
            <div>{{ $t(`comment_search.count_group`) }}</div>
            <div style="padding: 3px 20px;">{{ item.relation_counters.communities }}</div>
          </div>
        </div>
      </div>
      <div class="js-show-all-photo gallery">
        <div v-for="photo in item.photos" :key="photo.date" class="open-modal">
          <image-profile
              v-if="item.photos.length > 1"
              style="cursor: default;"
              :size="`${photoSize(item)}px`"
              :payload="photo.image"
          />
        </div>
      </div>
    </div>

    <div id="myModal" class="modal">
      <span class="close">&times;</span>
      <div class="modal-content">
        <img id="modalImg" src="" alt="Модальное изображение">
      </div>
    </div>

    <div style="margin-top: 30px">
      <h3>Загалом користувач залишив: {{ totalCountComments }} повідомлень.</h3>
    </div>

    <div v-for="treeComment in treeComments">

      <div style="padding: 20px;background: #263544; color: #fff;font-size: 25px;">
        <div>{{ $t('comment_search.to_group') }}:</div>
        <div v-for="data in treeComment.data">
          <span v-if="data.param === 'name'">{{ data.value }}</span>
        </div>
      </div>

      <div v-for="comment in treeComment.comments" style="background: #f1f1f1;">
        <CommentTree :comment="comment"/>
      </div>
    </div>
  </div>
</template>

<script>
import CommentTree from "@/components/export/CommentTree.vue";
import {Result} from "@/models/result";
import ImageProfile from "@/components/ImageProfile.vue";
import moment from "moment";
import dbKeyTelegramMonitor from "@/mixins/telegramMonitor";
import {mapGetters} from "vuex";

export default {
  props: {
    full: {
      type: Boolean,
      required: true,
    },
    meta: {
      type: Object,
      required: true
    },

    token: {
      type: String,
      required: true
    }
  },

  mixins: [dbKeyTelegramMonitor],

  components: {ImageProfile, CommentTree},

  data() {
    return {
      limit: 500,
      treeComments: [],
      totalCountComments: 0,
      parentSearch: {},
    }
  },

  mounted() {
    this.selectedLocale();
    this.getTelegramGroupCommentsPaginate().then(() => {
      this.$emit('ready');
    });
  },

  computed: {
    moment() {
      return moment
    },

    ...mapGetters('search_comment', ['telegramGroupComments']),
  },

  methods: {
    photoSize(item) {
      return item.data.length > 1 ? ((item.data.length + 1)* 52) - 3 : ((item.data.length + 1)* 52) - 31;
    },

     sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
     },

    async getTelegramGroupCommentsPaginate() {
      this.$store.commit('file_export/setClearProgress');
      let comments = [];

      if (this.full) {
        let resultDb = await Result.getResultByKey(this.meta.key);

        for (const result of resultDb.result.results) {
          for (const search_result of result.search_results) {
            for (const elementGroup of search_result.groups) {
              this.meta.payload.requestBody.group_id = [elementGroup.id];

              let key = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);
              let commentDb = await Result.getResultByKey(key)

              if (typeof commentDb === 'undefined') {
                await this.$store.dispatch('search_comment/getTelegramGroupComments', this.meta.payload.requestBody);

                for (const telegramGroupComment of this.telegramGroupComments) {
                  let payload = {
                    cache_search_enabled: true,
                    online_search_enabled: false,
                    value: this.meta.payload.requestBody.author.value,
                    sources: telegramGroupComment.source,
                    childKey: key,
                    countResult: telegramGroupComment.total_count,
                    requestBody: this.meta.payload.requestBody,
                  }

                  telegramGroupComment['param'] = this.meta.payload.requestBody.author.param;

                  await this.$store.dispatch('contacts/cacheWithResultsChild', {
                    data: this.telegramGroupComments,
                    type: 'telegram_monitor',
                    parentKey: this.meta.parent,
                    payload
                  });
                }

                await this.sleep(1000);

                commentDb = await Result.getResultByKey(key);
              }

              if (typeof commentDb !== 'undefined') {
                comments.push(commentDb);
              }
            }
          }
        }
      }

      if (!this.full) comments.push(await Result.getResultByKey(this.meta.key));

      let parentSearch = await Result.getResultByKey(this.meta.parent);
      this.parentSearch = parentSearch.result.results.find(item => item.source === "Telegram_scr_2023");

      let totalItems = comments.reduce((total, comment) => {
        let sourceComment = comment.result.results.find(item => item.source === "Telegram_scr_2023");
        let countPaginated = Math.ceil(sourceComment.matches_count / this.limit);
        return total + countPaginated;
      }, 0);

      let progress = 0;

      for (let comment of comments) {
        let sourceComment = comment.result.results.find(item => item.source === "Telegram_scr_2023");

        let countPaginated = Math.ceil(sourceComment.matches_count / this.limit);

        this.totalCountComments += sourceComment.total_count;

        let data = {
          body: { limit: this.limit, offset: 0 },
          query: { request_id: sourceComment.request_id, source_id: sourceComment.source_id }
        };

        let search_results = [];
        let authors = [];
        let group = {};

        for (let i = 0; i < countPaginated; i++) {
          progress++;

          let response = await this.$store.dispatch('search_comment/getTelegramGroupCommentsPaginate', data);
          data.body.offset = response.offset;

          search_results = search_results.concat(response.search_results);
          authors = authors.concat(response.relative_results.authors);
          group = response.relative_results.groups.shift();

          let percentage = Math.round((progress / totalItems) * 100);

          this.$store.commit('file_export/setProgress', percentage);
        }

        this.buildTreeComments(search_results, authors, group);
      }

      return new Promise(resolve => setTimeout(resolve, 200));
    },

    buildTreeComments(search_results, authors, group) {
      const map = {};

      search_results.forEach(item => {
        item.data = authors.find(author => author.id === item.author_id);
        item.children = [];
        map[item.id] = item;
      });

      const result = [];

      const calculateLevel = (item, currentLevel = 0) => {
        item.level = currentLevel;

        item.children.forEach(child => {
          calculateLevel(child, currentLevel + 1);
        });
      };

      search_results.forEach(item => {
        if (item.parent_id && map[item.parent_id] && !map[item.reply_to_id]) {
          map[item.parent_id].children.push(item);
        } else if (item.reply_to_id && map[item.reply_to_id]) {
          map[item.reply_to_id].children.push(item);
        } else {
          result.push(item);
        }
      });

      result.forEach(rootComment => {
        calculateLevel(rootComment);
      });

      group.comments = result;

      this.treeComments.push(group);
    },

    selectedLocale() {
      if (this.$i18n.locale === 'ua') {
        return 'uk';
      } else {
        return this.$i18n.locale
      }
    },
  }
};
</script>

<style scoped>
h1 {
  color: blue;
}

div {
  font-size: 14px;
  margin-bottom: 20px;
}
</style>