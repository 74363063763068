

export class BaseRepository {
    constructor(collectionName, db) {
        this.collection = db[collectionName];
        if (!this.collection) {
            throw new Error(`Collection '${collectionName}' does not exist in the database.`);
        }
    }

    async createOrUpdate(key, value) {
        try {
            const exists = await this.collection.where('key').equals(key).count();
            if (exists) {
                await this.collection.where('key').equals(key).modify({ result: value });
            } else {
                await this.collection.add({ key, result: value });
            }
        } catch (err) {
            console.error(`Error creating or updating in '${this.collection.name}':`, err);
            throw err;
        }
    }

    async getByKey(key) {
        try {
            return await this.collection.where('key').equals(key).last();
        } catch (err) {
            console.error(`Error fetching by key from '${this.collection.name}':`, err);
            throw err;
        }
    }

    async getById(id) {
        try {
            return await this.collection.get(id);
        } catch (err) {
            console.error(`Error fetching by ID from '${this.collection.name}':`, err);
            throw err;
        }
    }

    async getAll() {
        try {
            const results = {};
            await this.collection.each(item => {
                results[item.key] = item.result;
            });
            return results;
        } catch (err) {
            console.error(`Error fetching all from '${this.collection.name}':`, err);
            throw err;
        }
    }

    async update(key, value) {
        try {
            await this.collection.where('key').equals(key).modify({ result: value });
        } catch (err) {
            console.error(`Error updating in '${this.collection.name}':`, err);
            throw err;
        }
    }

    async delete(key) {
        try {
            await this.collection.where('key').equals(key).delete();
        } catch (err) {
            console.error(`Error deleting from '${this.collection.name}':`, err);
            throw err;
        }
    }
}