<template>
  <div class="card">
    <div>
      <pinch-zoom :limitZoom="5000">
<!--        <img :src="imgBase.source" class="showingImage" alt="">-->
        <image-profile
                      :modal="true"
                      :payload="photo.image"
                      :search-by-photo="false"
                      @loaded="loadedPhoto"
                      @imgSource="onLoadImg"/>
        <div v-if="canShowBoxesFromMainSearch" class="image" :style="{
          width: this.photoWidth + 'px',
        }">
          <div class="d-block d-lg-none position-absolute mobile-faces" :style="{
              height: face.heightShow < 15 ? '15px' : face.heightShow+ 'px',
              left: face.leftShow + 'px',
              top: face.topShow + 'px',
              width: face.widthShow < 15 ? '15px' : face.widthShow + 'px',
              'margin-right': `-${face.widthShow}px`
            }" v-for="face in faces" :key="'face_'+face.index"><span class="d-lg-none">{{ face.index + 1 }}</span></div>
          <a href="#" class="face-object d-none d-lg-flex" v-for="face in faces" @click.prevent="imageServiceSearch(face)"
            :key="face.index" :style="{
              height: face.heightShow + 'px',
              left: face.leftShow + 'px',
              top: face.topShow + 'px',
              width: face.widthShow + 'px',
              'margin-right': `-${face.widthShow}px`
            }" :disabled="searchLimit === 0 && typeof face.results === 'undefined'">
            <span v-if="typeof face.results !== 'undefined'"> {{face.results}}</span>
            <span class="loading-face" v-show="face.loading">
              <b-spinner></b-spinner>
            </span>
          </a>
        </div>
      </pinch-zoom>
      <div class="row m-t-10 d-lg-none">
        <div class="col-4 m-b-10" v-for="face in faces" :key="'button_'+face.index">
          <div class="d-grid">
            <b-button variant="primary" class="btn btn-sm"
                    @click.prevent="imageServiceSearch(face)"
                    :disabled="searchLimit === 0 && typeof face.results === 'undefined'">
              <span class="spinner-border spinner-border-sm" v-show="face.loading" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
              <i class="feather icon-search" v-show="!face.loading && typeof face.results === 'undefined'"></i>
              <i class="feather icon-link" v-show="typeof face.results !== 'undefined'"></i>
              {{face.index + 1 }} <span v-if="typeof face.results !== 'undefined'"></span>
              <span class="text-center" v-if="face.results">({{ face.results }})</span>
            </b-button>

          </div>
        </div>
      </div>
      <div class="download-wrapper" v-if="!fromMainSearch || isResultModal" >
        <a :href="imgBaseInside.source" class="btn btn-primary" :download="photoTitle" >
          <i class="feather icon-download"></i> {{ this.$t("user.save") }}
        </a>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapState } from "vuex";
import imgSearch from "../../mixins/imgSearch";
import PinchZoom from 'vue-pinch-zoom';
import MD5 from "crypto-js/md5";
import ImageProfile from "@/components/ImageProfile.vue";
import _ from 'underscore';
import { Sources } from "@/models/searchOptions/Sources";
import { Services } from "@/models/searchOptions/Servises";
import { Result } from "@/models/result";

export default {
  name: "FaceInner",
  props: {
    fromMainSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchResult: {
      type: Object,
      required: false,
    },
    photo: {
      type: Object,
      required: true
    },
    meta: {
      type: Object,
      required: true
    },
    imgBase: {
      required: true
    },
    search: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    ImageProfile,
    PinchZoom
  },
  mixins: [imgSearch],
  data() {
    return {
      photoWidth: 0,
      searchAlgorithms: localStorage.getItem('search_algorithms')
        ? Boolean(Number(localStorage.getItem('search_algorithms'))) : true,
      canShowBoxesFromMainSearch: false,
      imgBaseInside: {}
    }
  },
  computed: {
    loaderState() {
      return this.fromMainSearch && !this.search;
    },
    ...mapState({
      searchedImage: state => state.contacts.searchedImage,
      searchLimit: state => state.contacts.searchLimit
    }),
    ...mapGetters('contacts', ['searchOptions']),
    faceSelect() {
      const photoFaceSelect = !_.isUndefined(this.photo.face_select) ? this.photo.face_select : {};
      if (this.fromMainSearch && this.searchResult) {
        return this.searchResult.face_select ? this.searchResult.face_select : photoFaceSelect
      } else {
        // return this.photo.face_select ? this.photo.face_select : this.$store.state.contacts.faceSelect
        return photoFaceSelect;
      }
    },
    faces() {
      if (this.faceSelect.length && this.canShowBoxesFromMainSearch) {
        let coef = _.isUndefined(this.searchResult.photoParams) ? this.photoWidth / this.faceSelect[0].width : this.photoWidth / this.searchResult.photoParams.width;
        // console.log('coef', [coef, this.photoWidth, this.searchResult.photoParams.width]);
        
        const mutateObj = JSON.parse(JSON.stringify(this.faceSelect))
        return mutateObj.map(item => {
          return Object.assign({}, item, {
            heightShow: item.height * coef,
            height: item.height,
            leftShow: item.left * coef,
            left: item.left,
            widthShow: item.width * coef,
            width: item.width,
            topShow: item.top * coef,
            top: item.top,
            index: item.index,
            loading: false
          })
        })
      } else {
        return [];
      }
    },
    photoTitle() {
      if (this.imgBase.source) {
        let extensionBody = this.imgBase.source.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
        let extension = extensionBody.split('/')[1];
        if (extension === 'jpeg') {
          extension = 'jpg';
        }
        return MD5(this.imgBase.source).toString().slice(0, 10) + `.${extension}`;
      }
    },
    isResultModal() {
      return this.$route.name !== 'Home'
    }
  },

  watch: {
    faces() {
      this.$nextTick(() => {
        // this.canShowBoxesFromMainSearch = true;
      });
    }
  },
  methods: {
    loadedPhoto() {
      setTimeout(() => {
        this.photoWidth = document.querySelectorAll('.modal.show img')[0].width
        this.canShowBoxesFromMainSearch = true;
      }, 100)
      this.$nextTick(() => {
      })
    },
    onLoadImg(data) {
      this.imgBaseInside = data;
      // this.photoWidth = document.querySelectorAll('.modal.show img')[0].width
      // this.$nextTick(() => {
      //   this.photoWidth = document.querySelectorAll('.modal.show img')[0].width
      // })
      // this.canShowBoxesFromMainSearch = true;
    },
    async imageServiceSearch(face) {

      if ((typeof face.childKey === 'undefined' || !face.childKey) && typeof face.results === 'undefined') {
        if (this.searchLimit === 0) {
          return true;
        } else {
          this.searchRequest(face)
        }
      } else {
        const res = await Result.getResultByKey(face.childKey + '_' + face.index)
          await this.$router.push({name: 'Child', params: {id: res.id}})
        // this.$store.dispatch('contacts/updateResultChild', face.childKey + '_' + face.index).then(() => {
        //   if(this.$route.name !== 'Child') {
        //     this.$router.push({name: 'Child'})
        //   } else {
        //     this.$router.push({name: 'Home'}).then(() => {
        //       this.$router.push({name: 'Child'})
        //     })
        //   }
        //   this.isPhotoSegments = false;
        //   // document.location.reload();
        // })
      }
    },
    searchRequest(face) {
      face.loading = true;
      this.$forceUpdate();
      const services = new Services('image');
      const sources = new Sources('image')
      const additionOptions = {services: services.options, ...sources.options}
      let payload = {
        "portrait_id": this.photo.image.split('/')[6],
        
        "face_id": face.face_id,
      };
      payload = Object.assign(payload, additionOptions)

      // if (this.fromMainSearch) {
      //   payload.offline = this.searchResult.offline
      // } else {
      //   payload.offline = fullData.image.offline
      // }
      this.$store.dispatch('contacts/faceSearch', {
        payload,
        meta: this.meta
      }).then((data) => {
        // if (data.err) {
        //   this.$store.dispatch('contacts/faceSearch', {
        //     payload: {
        //       param: 'image',
        //       value: this.faceSelect.image_url
        //     },
        //     meta: this.meta
        //   }).then(data => {
        //     let payload = {
        //       "param": "image",
        //       "value": data.faceSelect.image_url,
        //       "session_key": data.face_select.session_key,
        //       "index": face.index,
        //     };
        //     // if (this.fromMainSearch) {
        //     //   payload.offline = this.searchResult.offline
        //     // } else {
        //     //   payload.offline = fullData.image.offline
        //     // }
        //     payload = Object.assign(payload, params["image"])
        //     this.$store.dispatch('contacts/faceSearch', {
        //       payload,
        //       meta: this.meta
        //     }).then((data) => {
        //       this.isPhotoSegments = false;
        //       data.payload = payload
        //       this.afterFragmentSearch(face, data);
        //       face.loading = false;
        //     });
        //   })
        // } else {
          data.payload = payload
          this.isPhotoSegments = false;
          this.afterFragmentSearch(face, data);
          face.loading = false;
          this.$forceUpdate();
        // }
      })
    },
  },
  mounted() {
    setTimeout(() => {
      this.photoWidth = document.querySelectorAll('.modal.show img')[0].width
    }, 100)
    window.addEventListener('resize', () => {
      this.photoWidth = document.querySelectorAll('.modal.show img')[0].width
    });
  }
}
</script>

<style lang="scss" scoped>
.image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.image .face-object {
  position: absolute;
  @media (min-width: 923px) {
    border: 2px solid #6e6eea;
    border-radius: 2px;
  }
  // min-width: 48px;
  // min-height: 48px;
}

.image .face-object[disabled] {
  border: 2px solid #bdbdbd;
  cursor: not-allowed;
}

.buttons {
  padding-bottom: 15px;
}
.button{
  border: solid 1px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  padding: 8px;
}

.save-photo.button .icon:first-child {
  margin-right: 10px;
}

.face-object>span {
  position: absolute;
  display: block;
  bottom: 0;
  width: 100%;
  height: 15px;
  background: rgba(255, 255, 255, 0.7);
  font-size: 10px;
}

.face-object .loading-face {
  top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

.face-object .loading-face .icon {
  margin: auto;
  @media (max-width: 992px) {
    display: none;
  }
}

.pinch-zoom-wrapper img {
  max-height: calc(100vh - 160px) !important;
  width: auto !important;
}

@media screen and (max-width: 360px) {
  .pinch-zoom-wrapper img {
    max-height: calc(100vh - 260px) !important;
  }
}

.mobile-faces {
  background: rgba(255, 255, 255, .7);
  font-size: 10px;
  text-align: center;
}

.download-wrapper {
  padding-bottom: 15px;
  margin-top: 0.7rem;
  display: flex;
  justify-content: center;
}
</style>