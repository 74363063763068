import Vue from "vue";
import HtmlTemplate from "@/components/export/HtmlTemplate.vue";
import i18n from "@/i18n";
import store from '@/store'

export async function generateHtml(full, meta, token) {
    const HtmlTemplateComponent = Vue.extend(HtmlTemplate);

    const instance = new HtmlTemplateComponent({
        propsData: { full, meta, token },
        i18n,
        store,
    });

    const container = document.createElement("div");
    instance.$mount(container);

    await new Promise((resolve) => {
        instance.$on('ready', () => resolve());
    });

    const jsCode = `
        <script>
        let btn = document.querySelector('.js-btn-show-more');
        
        btn.addEventListener('click', (e) => {
            let allPhoto = document.querySelector('.js-show-all-photo');
            toggleHeight(allPhoto);
        });
        
        function toggleHeight(el) {
            if (el.dataset.expanded === "true") {
                collapseElement(el);
            } else {
                expandElement(el);
            }
        }
        
        function expandElement(el) {
            let startHeight = el.offsetHeight;
            el.style.height = "auto";
            let targetHeight = el.scrollHeight;
            el.style.height = startHeight + "px";
        
            requestAnimationFrame(() => {
                el.style.transition = "height 0.5s ease-in-out";
                el.style.height = targetHeight + "px";
            });
        
            el.dataset.expanded = "true";
        }
        
        function collapseElement(el) {
            let startHeight = el.offsetHeight;
            el.style.height = startHeight + "px";
        
            requestAnimationFrame(() => {
                el.style.transition = "height 0.5s ease-in-out";
                el.style.height = "0px";
            });
        
            el.dataset.expanded = "false";
        }
        
        
        const modal = document.getElementById("myModal");
        const modalImg = document.getElementById("modalImg");
        const closeModal = document.querySelector(".close");

        document.querySelectorAll(".open-modal").forEach(img => {
            img.addEventListener("click", function() {
                modal.style.display = "block";
                modalImg.src = img.children[0].children[0].src;
            });
        });

        closeModal.addEventListener("click", function() {
            modal.style.display = "none";
        });

        modal.addEventListener("click", function(event) {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        });
        </script>
    `;

    const styles = `
        <style>
            .js-show-first-photo {
                position: relative;
            }
            
            .js-show-all-photo {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                height: 0;
                overflow: hidden;   
                transition: height 0.5s ease-in-out; 
            }
            
            .js-btn-show-more {
                position: absolute;
                bottom: 0;
                background: #4099ff;
                padding: 5px 0;
                color: #fff;
                width: 100%;
                text-align: center;
                cursor: pointer;
            }

        .gallery img {
            width: 150px;
            cursor: pointer;
            transition: transform 0.2s ease-in-out;
        }

        .gallery img:hover {
            transform: scale(1.1);
        }

        .modal {
            display: none;
            position: fixed;
            z-index: 1000;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
        }

        .modal-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 90%;
            max-height: 90%;
            background-color: #fff;
            padding: 10px;
            border-radius: 5px;
        }

        .modal img {
            width: 100%;
            height: auto;
            display: block;
        }

        .close {
            position: absolute;
            top: 10px;
            right: 20px;
            font-size: 30px;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
        }
        </style>
    `;

    let titleName = '';

    meta.cost.forEach(item => {
        titleName = `${meta.parent.split('_').at(-1)} - ${item.source_locales[selectedLocale()]}`
    });

    const html = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${titleName}</title>
    </head>
    <body>
      ${instance.$el.outerHTML}
      ${jsCode}
      ${styles}
    </body>
    </html>
  `;

    instance.$destroy();

    return {html: html, filename: `${meta.parent}_telegram_comments`};
}

export async function downloadHtml(full, meta, token) {
    const html = await generateHtml(full, meta, token);

    const blob = new Blob([html.html], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = html.filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    return true;
}

export function selectedLocale() {
    if (i18n.locale === 'ua') {
        return 'uk'
    } else {
        return i18n.locale
    }
}