<template>
  <b-overlay :show="show" no-wrap fixed z-index="9999" class="overlay">
    <template #overlay>
      <div style="width: 500px">
        <div>
          <h3 class="text-muted">
              <span v-if="!progress">
                {{ $t('comment_search.info_download_prepare_data') }} <b-spinner small></b-spinner>
              </span>
            <span v-if="progress">
                {{ $t('comment_search.info_download_start_data') }}
              </span>
          </h3>
        </div>
        <b-progress v-if="progress" height="2rem">
          <b-progress-bar :value="progress" :max="100" show-progress animated>
            <template #default>
              {{ progress }} %
            </template>
          </b-progress-bar>
        </b-progress>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Overlay",

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters('file_export', ['progress']),
  }
}
</script>

<style scoped>
.overlay {
  color: #fff;
}

.overlay >>> .progress {
  font-size: 20px;
}
</style>