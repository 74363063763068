<template>
  <div class="d-flex flex-row-reverse">
    <button
        v-if="search_rates?.defaults"
        :content="$t('comment_search.hint.detail_preview')"
        v-tippy="{ placement : 'top',  arrow: true }"
        class="btn btn-primary m-r-10 m-b-10 m-t-5"
        @click="$bvModal.show(`telegram-group-${recordId}`)"
    >
      <span v-if="search_rates?.defaults">
        {{ $t('comment_search.detail_with_credit', {credit: search_rates?.defaults?.offline_result_price}) }}
      </span>
    </button>

    <b-modal
        size="lg" scrollable
        :id="`telegram-group-${recordId}`"
        centered
        hide-footer
        @hidden="closeModal"
        @show="openModal"
    >
      <template #modal-title>
        <span>Telegram Monitor: {{ historyParams?.author?.value }}</span>
      </template>

      <div class="main-tabs">
        <b-tabs content-class="p-10 m-t-20">
          <b-tab :title="$t('comment_search.group')" active @click="selectTab(false)">
            <b-container>
              <b-row class="align-items-center justify-content-center">
                <b-col cols="3">{{ $t(`comment_search.showPeriod`) }}:</b-col>
                <b-col cols="12" md="3">
                  <date-picker
                      :disabled-date="disabledDateStart"
                      :disabled-calendar-changer="disabledDateStart"
                      v-model="selectedPeriodFrom"
                      type="date"
                      format="DD.MM.YYYY"
                      lang="ua"
                      :disabled="allPeriod"
                      :placeholder="$t(`comment_search.start_date`)"
                      :editable="false" />
                </b-col>
                <b-col cols="12" md="3">
                  <date-picker
                      :disabled-date="disabledDateFinish"
                      :disabled-calendar-changer="disabledDateFinish"
                      v-model="selectedPeriodTo"
                      type="date"
                      :disabled="allPeriod"
                      :placeholder="$t(`comment_search.end_date`)"
                      format="DD.MM.YYYY"
                      :editable="false" />
                </b-col>
                <b-col cols="12" md="3" class="border-checkbox-section">
                  <div class="border-checkbox-group border-checkbox-group-primary">
                    <input
                        type="checkbox"
                        class="border-checkbox"
                        id="all_period"
                        v-model="allPeriod"
                    >
                    <label
                        for="all_period"
                        class="border-checkbox-label"
                    >
                      {{ $t('comment_search.full_date') }}
                    </label>
                  </div>
                </b-col>
                <div class="d-flex justify-content-center m-t-20">
                  <span class="w-25"
                        v-if="isFormReady"
                        v-tippy="{ placement: 'top', arrow: true, content: $t(`comment_search.quick_search_error_empty_fields`) }"
                  >
                    <b-button
                        disabled
                        class="w-100"
                        variant="primary"
                    >
                        {{ $t(`comment_search.search`) }}
                    </b-button>
                  </span>

                  <button
                      v-else
                      class="btn btn-primary w-25"
                      @click="getCountComments"
                  >
                    {{ $t(`comment_search.search`) }}
                  </button>
                </div>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab :title="$t('comment_search.quickSearch')" @click="selectTab(true)">
            <b-container>
              <b-row class="align-items-center m-b-20">
                <b-col cols="3">{{ $t(`comment_search.searchQuery`) }}</b-col>
                <b-col>
                  <b-form-input v-model="searchQuery" />
                </b-col>
              </b-row>

              <b-row  class="align-items-center m-b-20">
                <b-col cols="3">{{ $t(`comment_search.groupName`) }}</b-col>
                <b-col>
                  <b-form-input v-model="groupName" />
                </b-col>
              </b-row>
            </b-container>

            <b-container>
              <b-row class="align-items-center">
                <b-col cols="3">{{ $t(`comment_search.showPeriod`) }}:</b-col>
                <b-col cols="12" md="3">
                  <date-picker
                      :disabled-date="disabledDateStart"
                      :disabled-calendar-changer="disabledDateStart"
                      v-model="selectedPeriodFrom"
                      type="date"
                      :disabled="allPeriod"
                      format="DD.MM.YYYY"
                      lang="ua"
                      :placeholder="$t(`comment_search.start_date`)"
                      :editable="false" />
                </b-col>
                <b-col cols="12" md="3">
                  <date-picker
                      :disabled-date="disabledDateFinish"
                      :disabled-calendar-changer="disabledDateFinish"
                      v-model="selectedPeriodTo"
                      type="date"
                      :disabled="allPeriod"
                      :placeholder="$t(`comment_search.end_date`)"
                      format="DD.MM.YYYY"
                      :editable="false" />
                </b-col>
                <b-col cols="12" md="3" class="border-checkbox-section">
                  <div class="border-checkbox-group border-checkbox-group-primary">
                    <input
                        type="checkbox"
                        class="border-checkbox"
                        id="all_period"
                        v-model="allPeriod"
                    >
                    <label
                        for="all_period"
                        class="border-checkbox-label"
                    >
                      {{ $t('comment_search.full_date') }}
                    </label>
                  </div>
                </b-col>
                <div class="d-flex justify-content-center m-t-20">
                  <span class="w-25"
                        v-if="isFormReady"
                        v-tippy="{ placement: 'top', arrow: true, content: $t(`comment_search.quick_search_error_empty_fields`) }"
                  >
                    <b-button
                        disabled
                        class="w-100"
                        variant="primary"
                    >
                        {{ $t(`comment_search.search`) }}
                    </b-button>
                  </span>

                  <button
                      v-else
                      class="btn btn-primary w-25"
                      @click="getCountComments"
                  >
                    {{ $t(`comment_search.search`) }}
                  </button>
                </div>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
      </div>

      <preview-skeleton v-if="!isLoaded && isViewSkeleton" />

      <div
          v-if="isLoaded && commentGroups && !isModelExistRecord"
          class="m-t-20"
      >
        <div v-for="commentGroup in commentGroups">
          <div v-if="isLoaded && !commentGroup.search_results.length" class="d-flex justify-content-center m-t-20 bg-light">
            {{ $t('result.nothing_found') }}
          </div>

          <div v-for="search_result in commentGroup.search_results" :key="search_result.period_from">
            <div v-if="search_result.count">{{ $t(`comment_search.countComments`) }}: {{ search_result.count }}</div>
            <div v-if="search_result.count" class="d-flex">
              <span class="p-r-10">{{ $t(`comment_search.periodFrom`) }}:</span>
              <div>{{ moment(search_result.period_from, 'X').format('DD MMM YYYY') }} - {{ moment(search_result.period_to, 'X').format('DD MMM YYYY') }}</div>
            </div>

            <table class="table table-hover table-responsive-sm">
              <thead>
              <th>{{ $t('ui.group') }}</th>
              <th>{{ $t('comment_search.periodFrom') }}</th>
              <th>{{ $t('comment_search.comment') }}</th>
              </thead>
              <tbody>
              <tr v-for="group in search_result.groups" :key="group.id">
                <td>
                  <div class="d-flex">
                    <div class="col-12 col-lg-3 border-checkbox-section">
                      <div class="border-checkbox-group border-checkbox-group-primary">
                        <input
                            :disabled="!group.count"
                            type="checkbox"
                            class="border-checkbox"
                            :id="'checkbox-' + group.id" v-model="groups"
                            :key="group.id" :value="group"
                        >
                        <label
                            :for="'checkbox-' + group.id"
                            class="border-checkbox-label"
                        >
                          <span v-if="group.data">{{ group.data.find(item => item.param === 'name').value }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                <span v-if="group.count">
                  {{ moment(group.period_from, 'X').format('DD MMMM YYYY') }} -
                  {{ moment(group.period_to, 'X').format('DD MMMM YYYY') }}
                </span>
                </td>
                <td>{{ group.count || '0'}}</td>
              </tr>
              </tbody>
            </table>

            <span v-if="search_result.count">
              {{ $t(`comment_search.countSelectedGroup`) }}: {{ groups.length }},
              {{ $t('comment_search.money_written', {credit: search_rates?.defaults?.offline_result_price} )}}
            </span>
          </div>
        </div>

        <div class="p-10">
          <b-container>
            <b-row>
              <div class="d-flex justify-content-center m-t-40">
              <span class="w-25" v-if="!groups.length" v-tippy="{ placement: 'top', arrow: true, content: $t('comment_search.hint.show_comment_preview') }">
                <b-button
                    disabled
                    class="w-100"
                    variant="primary"
                >
                    {{ $t('search.open') }}
                </b-button>
              </span>

                <b-button
                    v-if="groups.length"
                    class="w-25"
                    variant="primary"
                    @click="$bvModal.show(`bv-modal-${recordId}`)"
                >
                  {{ $t('search.open') }}
                </b-button>
              </div>
            </b-row>
          </b-container>
        </div>
      </div>
    </b-modal>

    <comment-main-search :data="searchData" :groups="groups" :modal-id="recordId"/>

    <b-modal
        v-model="isModelExistRecord"
        centered
        :title="$t('search.query_exist')"
        hide-footer
        has-modal-card
        trap-focus :destroy-on-hide="false" aria-role="dialog"
        aria-modal
        @hide="localComments"
    >
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <div class="d-flex">
              <span class="p-r-10">{{ $t(`comment_search.periodFrom`) }}:</span>
              <span v-if="typeof historyParams.period_from !== 'undefined' && typeof historyParams.period_to !== 'undefined'">
                <span v-if="typeof historyParams.period_from !== 'undefined'">
                  {{ moment(historyParams.period_from, 'X').format('DD MMM YYYY') }} -
                </span>
                <span v-else>sxsx</span>
                <span v-if="typeof historyParams.period_to !== 'undefined'">
                  {{ moment(historyParams.period_to, 'X').format('DD MMM YYYY') }}
                </span>
                <span v-else>{{ $t('comment_search.full_date')}}</span>
              </span>
              <span v-else>{{ $t('comment_search.full_date')}}</span>
            </div>

            <b-button variant="primary" class="mt-3" @click="remoteGroups()">
              {{ $t('search.update') }}
              <span v-if="search_rates?.defaults">({{ search_rates?.defaults?.offline_result_price}}K)</span>
            </b-button>
            <b-button variant="secondary" class="mt-2" @click="localComments()">{{ $t('search.open') }}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import CommentMainSearch from "@/components/search/comments/commentMainSearch.vue";
import {Result} from "@/models/result";
import telegramMonitor from "@/mixins/telegramMonitor";
import PreviewSkeleton from "@/components/search/comments/components/preview_skeleton.vue";

export default {
  name: "SearchComment",

  mixins: [telegramMonitor],

  setup() {
    return {
      moment
    }
  },

  components: {
    CommentMainSearch,
    DatePicker,
    PreviewSkeleton,
  },

  props: ['recordId', 'result'],

  data() {
    return {
      firstRecordId: '',
      isModelExistRecord: false,
      isLoaded: false,
      isViewSkeleton: false,
      selectedPeriodFrom: '',
      selectedPeriodTo: '',
      groups: [],
      searchQuery: '',
      groupName: '',
      parentKey: '',
      searchData: {},
      searchMainAttr: {},
      isFullParams: false,
      allPeriod: false,
      commentGroups: [],
    }
  },

  computed: {
    ...mapGetters('search_comment', ['telegramComment', 'historyParams']),
    ...mapGetters('search_rates', ['search_rates', 'servicesList']),

    isFormReady() {
      return this.isFullParams
          ? !((this.allPeriod || Boolean(this.selectedPeriodFrom) || Boolean(this.selectedPeriodTo)) && Boolean(this.searchQuery.length || this.groupName.length))
          : !(this.allPeriod || Boolean(this.selectedPeriodFrom) || Boolean(this.selectedPeriodTo))
    },
  },

  mounted() {
    this.$store.dispatch('search_rates/getSearchRates');
  },

  methods: {
    ...mapMutations('search_comment', ['setClearTelegramComments']),

    disabledDateStart(date) {
      let today = new Date().setHours(0, 0, 0, 0)

      if (Boolean(this.selectedPeriodTo)) {
        today = this.selectedPeriodTo;
      }

      const timestamp = Math.floor(Date.UTC(1970, 0, 1, 0, 7, 2) / 1000);

      return date < timestamp || date > today;
    },

    disabledDateFinish(date) {
      let timestamp = Math.floor(Date.UTC(1970, 0, 1, 0, 7, 2) / 1000);

      if (Boolean(this.selectedPeriodFrom)) {
        timestamp = this.selectedPeriodFrom;
      }

      const today = new Date().setHours(0, 0, 0, 0)
      return date < timestamp || date > today;
    },

    selectTab(isTab) {
      this.isFullParams = isTab;
      this.clearDataForm();
    },

    clearDataForm() {
      this.selectedPeriodFrom = '';
      this.selectedPeriodTo = '';
      this.searchQuery = '';
      this.groupName = '';
    },

    async firstOpen() {
      moment.locale(this.locale());

      this.getSearchAttr();

      let firstData = {... this.searchData};
      firstData.main = true;

      this.selectedPeriodFrom = moment().subtract(1, 'year').startOf('day');
      this.selectedPeriodTo = moment().startOf('day');
      this.prepareBodyRequest();

      this.parentKey = this.generatePreviewDbKeyWithParent(firstData, 'preview');

      await this.$store.dispatch('search_comment/getLocalPreviewTelegramGroupComments', this.parentKey);

      if (this.telegramComment.length) {
        this.isModelExistRecord = true;
        this.clearDataForm();
      }

      if (!this.telegramComment.length) {
        await this.remoteGroups();
      }

      this.groupComments();

      await this.$store.dispatch('search_rates/getSearchRates');
    },

    async getCountComments() {
      this.groups = [];
      this.prepareBodyRequest();

      this.parentKey = this.generatePreviewDbKeyWithParent(this.searchData, 'preview');
      const response = await this.$store.dispatch('search_comment/getLocalPreviewTelegramGroupComments', this.parentKey);

      if (!Object.keys(response).length) {
        this.clearData();

        await this.remoteGroups();
        this.groupComments();
        return;
      }

      if (Object.keys(response).length) {
        this.isModelExistRecord = true;
      }

      this.groupComments();
    },


    groupComments() {
      this.commentGroups = [... this.telegramComment];

      this.commentGroups.forEach((source) => {
        if (source.search_results === null) {
          source.search_results = [];
          source.search_results.push(source.relative_results);
        } else {
          source.relative_results.groups.forEach((group) => {
            let findGroup = source.search_results.find(Boolean).groups.find(item => item.id === group.id);
            if (typeof findGroup === 'undefined') source.search_results.find(Boolean).groups.push(group);
            if (typeof findGroup !== 'undefined') {
              Object.assign(findGroup, group);
            }
          });
        }
      });
    },

    async remoteGroups() {
      this.isModelExistRecord = false;
      await Result.deleteResult(this.parentKey);

      this.isLoaded = false;
      this.isViewSkeleton = true;

      await this.$store.dispatch('search_comment/getTelegramComments', this.searchData);

      this.isViewSkeleton = false;

      if (this.telegramComment.length) {
        for (const group of this.telegramComment) {
          let payload = {
            cache_search_enabled: true,
            online_search_enabled: false,
            value: this.searchData.author.value,
            sources: group.source,
            childKey: this.parentKey,
            requestBody: this.searchData,
          };

          await this.$store.dispatch('contacts/cacheWithResultsChild', {
            data: [group],
            type: 'telegram_monitor_preview',
            parentKey: this.$store.getters["contacts/meta"].key,
            payload
          });
        }
      }

      this.isLoaded = true;
    },

    getSearchAttr() {
      this.searchMainAttr = {
        param: this.result.param,
        value: this.result.value
      };
    },

    prepareBodyRequest() {
      const data = {
        'author': this.searchMainAttr,
      }

      if (this.isFullParams) {
        if (this.groupName) data['group'] = {"param": "name", "value": this.groupName};
        if (this.searchQuery) data['search_query'] = this.searchQuery;
      }

      if (!this.allPeriod) {
        if (this.selectedPeriodFrom) {
          data['period_from'] = moment(this.selectedPeriodFrom).format("X");
        }

        if (this.selectedPeriodTo) {
          data['period_to'] = moment(this.selectedPeriodTo).add(23, 'hour').add(59, 'minute').add(59, 'second').format("X");
        }
      }

      this.searchData = data;
    },

    localComments() {
      this.isModelExistRecord = false;
      this.isViewSkeleton = false;
      this.isLoaded = true;
    },

    clearData() {
      this.setClearTelegramComments()
      this.isLoaded = false;
    },

    closeModal() {
      this.clearData();
      Object.assign(this.$data, this.$options.data.call(this));
    },

    openModal() {
      this.firstOpen()
    },

    locale() {
      return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale
    },
  }
}
</script>

<style scoped>
.main-tabs >>> .comment-tab {
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #37474f;
  border-radius: 0;
  color: #37474f;
  width: 200px;
  text-align: center;
}

.main-tabs >>> .comment-tab.active, .main-tabs >>> .comment-tab.active:hover {
  color: #0275d8;
  text-align: center;
  border-bottom: 2px solid #0275d8;
}
</style>