var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photoWrapper"},[_c('div',{staticClass:"facePreview",style:({
            backgroundSize: `${_vm.bgwidth}px ${_vm.bgheight}px`,
            backgroundImage: 'url(' + _vm.facePreviewSrc + ')',
            backgroundPosition: `${_vm.left}px ${_vm.top}px`,
            width: _vm.propWidth ?  `${_vm.propWidth}px` : `${_vm.width}px`,
            height: _vm.propWidth ?  `${_vm.propWidth}px` : `${_vm.height}px`,
        }),attrs:{"id":"facePreview"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }