<template>
  <div>
    <form @submit.prevent="beforeSearch" enctype="multipart/form-data">
      <div class="imageUploadContainer row form-group" :class="{ 'has-name': !!file }">
        <div class="col-12 col-lg-3 m-b-15 mb-lg-0">
          <div class="d-grid">
            <div class="file btn-primary btn"
                 style="text-transform: inherit"
                 :title="$t('search.file')"
                 :class="{'has-name': !!file}">
              {{ $t('search.Click_to_upload') }}
              <input type="file" name="file" ref="photo" @change="previewFile" accept="image/*" id="file">
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <img class="photo photo-preview" :src="photoPreviewSrc" alt="" />
        </div>
      </div>
      <div class="web-camera-container" style="margin-top: 0.75rem" v-if="isCameraOpen">
        <div v-show="isCameraOpen && isLoading" class="camera-loading">
          <ul class="loader-circle">
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>

        <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ flash: isShotPhoto }">
          <div v-if="isPhotoTaken" class="camera-shutter" :class="{ flash: isShotPhoto }"></div>
          <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>
          <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
        </div>

        <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
<!--                  <span class="button" @click="takePhoto">-->
                    <!-- <b-icon pack="fas" icon="camera" size="is-medium" /> -->
<!--                  </span>-->

          <!--                <button type="button" class="button" @click="takePhoto">-->
          <!--                  <img src="https://img.icons8.com/material-outlined/50/000000/camera&#45;&#45;v2.png">-->
          <!--                </button>-->
        </div>
      </div>
      <OptionsSearch param="image" @push="setPayload" @invalid="setInvalid" />
      <div class="d-grid" v-if="!disallowFreeSpace">
        <b-button class="btn" variant="primary"  type="submit" :disabled="$v.$invalid">{{ $t("search.search") }}</b-button>
      </div>
      <div v-else class="text-danger f-w-700">{{ $t('ui.fullLocalStorage') }}</div>
    </form>
  </div>
</template>

<script>
import OptionsSearch from "@/components/search/optionsSearch";
import imgSearch from "@/mixins/imgSearch";

import {mapGetters} from "vuex";

import Compressor from "compressorjs";

export default {
  name: "imageForm",
  components:{ OptionsSearch },
  mixins: [imgSearch],
  data() {
    return {
      payload: {
        param: 'image',
        value:''
      },
      invalid: true,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      photoFile: null,
      showPhoto: false,
      photoPreviewSrc: "",
      file: null,
      searchSettings : {}
    }
  },
  validations() {
    return {
      payload: {
        optionsValid() {
          return !this.invalid
        }
      },
      file: {
        required(val) {
          return !!val
        }
      }
    }
  },
  computed:{
    ...mapGetters("ui", ["countries", 'disallowFreeSpace']),
    ...mapGetters("auth", ["user"]),
    formDataPayload() {
      let formData = new FormData();
      if (this.file) {
        this.searchSettings.param = this.payload.param
        this.searchSettings.cache_search_enabled = false
        this.searchSettings.online_search_enabled = this.payload.online_search_enabled
        this.searchSettings.services = this.payload.services
      }
      return formData;
    },
  },
  watch:{
    file(val) {
      this.photoPreviewSrc = window.URL.createObjectURL(val);
      this.payload.value = val;
    },
  },
  methods:{

    beforeSearch() {
      let value = this.payload.value;
      let searchesExist = this.$store.state.contacts.searches.filter((item) => {
        return item.keyVal === `${this.payload.param}_${value}_${this.user.login}`;
      });
      let withFail = searchesExist.filter((item) => {
        return !!item.error;
      });
      if (searchesExist.length) {
        this.modalDialog = true;
        if (withFail.length) {
          this.showBtn = false;
        } else {
          this.showBtn = true;
        }
      } else {
        this.addSearch();
        try {
          this.$matomo.trackEvent('Web Search Main Search '+ this.payload.param, 'WasClicked')
          if(this.optionsForAnalytics.length) {
            this.$matomo.trackEvent('Web Options '+ this.optionsForAnalytics, 'WasClicked');
          }
        } catch (e) {
          console.log('ignore error');
        }
      }
    },

    addSearch() {
        const formPayload = this.formDataPayload;
        const searchSettings = this.searchSettings
        const storeObj = this.$store;
        const date = Date.now()
        new Compressor(this.file, {
          maxWidth: 1200,
          maxHeight: 1200,
          quality: 0.8,
          success(file) {
            formPayload.delete('image');
            formPayload.append("image", file);
            storeObj.dispatch("contacts/searchFile", {
              payload: formPayload,
              // file: this.file.name,
              file: this.file.name + date,
            }).then((searchFileResp) => {
              if (searchFileResp.data.faces.length === 1){
                storeObj.dispatch("contacts/faceSearch", {
                  payload: {
                    portrait_id: searchFileResp.data.portrait_id,
                    face_id: searchFileResp.data.faces[0].face_id,
                    cache_search_enabled: searchSettings.cache_search_enabled,
                    online_search_enabled: searchSettings.online_search_enabled,
                    services: searchSettings.services,
                  },
                  meta: {
                    portrait_id: searchFileResp.data.portrait_id,
                    face_id: searchFileResp.data.faces[0].face_id,
                  }
                }).then((faceSearchResp)=> {
                  storeObj.dispatch('contacts/updateSingleFaceResultInfo', {faceSearchResp: faceSearchResp, searchFileResp: searchFileResp , fileName: this.file.name + date})
                })
              }
              storeObj.dispatch('ui/getFreeSpaceInfo')
            });
          },
        });
        this.payload.value = null;
        this.val = "";
    },


    setPayload(data) {
      this.payload = Object.assign(this.payload, data)
    },
    setInvalid(optionsInvalid) {
      this.invalid = optionsInvalid
    },
    previewFile() {
      this.file = this.$refs.photo.files[0];
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        const FLASH_TIMEOUT = 50;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);

      this.photoFile = document
          .getElementById("photoTaken")
          .toDataURL("image/jpeg");
      this.convertImageDataToFile(this.photoFile);
    },
    convertImageDataToFile: function (dataURl) {
      let name = "photo: " + new Date().toLocaleTimeString();
      let arr = dataURl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      this.file = new File([u8arr], name, { type: mime });
    },

  },
}

</script>