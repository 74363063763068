<template>
  <div class="card">

    <div class="searchResultContainer">
      <header class="card-header" v-if="isIcon">
        <div class="d-flex align-items-center">
          <figure class="flex-shrink-1 mb-0">
            <p class="image is-24x24 mb-0">
              <img  :src="icon.type !== 'face' ? prefix+'images/db.png' : icon.icon" @imgSource="onLoadImg">
            </p>
          </figure>
          <div class="flex-grow-1 ms-3">
            <div class="content">
              <h2 style="font-size: 1.75em;" class="mb-0">
<!--                {{ icon.type !== 'face' ? "Local Database" : icon.source }} ({{searchResults.length}}):-->
                {{ icon.type !== 'face' ? "Local Database" : icon.source }} ({{resultsCount}}):
              </h2>
            </div>
          </div>
          <figure class="media-right" style="margin-top: 0.6rem" @click="editMode()">
            <i class="feather icon-edit" style="cursor: pointer;"  :class="{'editButtonActive': editResults}"
               :content="$t('result.edit_result')"
               v-tippy="{ placement : 'top',  arrow: true }"
            ></i>
          </figure>
        </div>
<!--        <div class="btn-group m-b-30" v-show="editResults" style="width: 100%;">-->
<!--          <div class="btn btn-outline-secondary" style="" @click="selectAll(true)">{{ $t("result.select_all") }}</div>-->
<!--          <div class="btn btn-outline-secondary" style="" @click="selectAll(false)">{{ $t("result.remove_all") }}</div>-->
<!--          <div class="btn btn-outline-danger" style="" @click="deleteRmModal()">{{ $t("result.delete_selected") }}-->
<!--          </div>-->
<!--        </div>-->

        <div class="contact-controls-buttons" v-if="editResults">
          <b-button class="button" variant="danger"   @click="deleteRmModal()">{{ $t("result.delete_selected") }}</b-button>
          <div style="display:flex; justify-content: start; width: 100%">
            <b-button v-if="result.search_results && result.search_results.length > 3" class="button" variant="primary"  @click="selectAll(true)" style="margin-right: 8px">{{ $t("result.select_all") }}</b-button>
            <b-button v-if="result.search_results && result.search_results.length > 3" class="button" variant="secondary"  @click="selectAll(false)">{{ $t("result.remove_all") }}</b-button>
          </div>
        </div>



      </header>
      <div class="card-body">

        <div class="searchResults list-group" v-if="searchResults.length && showResults">
          <div class="list-group-item" v-for="(searchElement, index) in searchResults" :key="index">
            <div class="elementPreview d-block d-lg-flex m-b-10">
              <div class="flex-shrink-1 result-image-wrapper">
                <searched-face :ref="`searchedFace${index}`" />
                <span class="score-photo">
                  <i class="feather icon-chevron-right"></i>
                  <span v-if="searchElement.search_result.photos.score" 
                    :content="$t('photo_fragment.similarity_percentage')" v-tippy="{ placement : 'top',  arrow: true }">
                    {{ Number((searchElement.search_result.photos.score).toFixed(1)) }}%
                  </span>
                </span>
                <photo-fragment :meta="meta" :photo="searchElement.search_result.photos" :source="result.source" :is-child="true"/>
<!--                <image-profile class="imageContainer" :searchByPhoto="true"-->
<!--                  :payload="searchElement.search_result.photos.thumbnail || searchElement.search_result.photos.image" :face-search-global="true"-->
<!--                  @preview="preview(searchElement)"-->
<!--                  style="margin:0; display: flex; align-items: center; justify-content: center; margin-top: 5px;"-->
<!--                  @search="showModal(searchElement.search_result.photos.image)" />-->
              </div>

               <b-modal style="min-height: 640px" centered v-model="searchElement.isPhotoSegments"  scroll="keep" hide-footer>
                <pinch-zoom>
                  <image-profile style="margin-bottom: 80px;margin-top: 80px"
                                 :modal="true"
                                 :payload="searchElement.search_result.photos.image"
                                 />
                </pinch-zoom>
              </b-modal>
              <div class="flex-grow-1 ms-lg-3 photo-result-text">
                <div class="align-items-center align-self-start flex-row d-flex m-l-lg-15" style="padding-left: 8px;height: 100%; width: 100%">
                  <div v-if="searchElement.type !== 'face'" class="is-24x24 d-flex m-r-5">
                    <img :src="searchElement.icon" :alt="searchElement.param">
                  </div>
                  <div style="width: 100%; line-height: 20px;" class="m-b-10 mb-lg-0">
                    <div v-if="searchElement.type === 'database'">
                      {{ typeof searchElement.source_locales !== 'undefined' ? searchElement.source_locales[locale] : searchElement.source }}
                    </div>
                    <span class="link-text" v-else-if="typeof searchElement.search_result.photos.source_url !== 'undefined'">{{
                    $t("result.source") }}:
                      <span
                        v-html="$options.filters.isLink($options.filters.makeEmoji(searchElement.search_result.photos.source_url.toString()))"></span>
                    </span>
                    <span v-else-if="typeof searchElement.search_result.data[1] !== 'undefined' && isAnyUrl(searchElement.search_result.data)">
                      {{ $t('params.' + searchElement.search_result.data[indexOfUrl(searchElement.search_result.data)].param) | paramName }}:
                      <span class="link-text"
                        v-html="$options.filters.isLink($options.filters.makeEmoji(searchElement.search_result.data[indexOfUrl(searchElement.search_result.data)].value.toString()))"></span>
                    </span>
                    <span v-else>
                      {{ $t('params.' + searchElement.search_result.data[0].param) | paramName }}:
                      <span class="link-text"
                        v-html="$options.filters.isLink($options.filters.makeEmoji(searchElement.search_result.data[0].value.toString()))"></span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="d-lg-flex d-grid align-items-lg-center  m-b-lg-0">
                <button class="btn btn-outline-primary d-lg-none" v-if="searchElement.search_result.data.length" @click="searchElement.displayDetail = !searchElement.displayDetail">
                  <i class="feather"
                    :class="{'icon-chevron-up': searchElement.displayDetail, 'icon-chevron-down': !searchElement.displayDetail}"></i>
                </button>
                <i class="feather d-none d-lg-block"
                    v-if="searchElement.search_result.data.length" @click="searchElement.displayDetail = !searchElement.displayDetail"
                    :class="{'icon-chevron-up': searchElement.displayDetail, 'icon-chevron-down': !searchElement.displayDetail}"></i>
              </div>
              <!-- <b-tooltip v-if="searchElement.search_result.data.length" label="Детальная информация">
                <b-icon
                    style="cursor: pointer"
                    pack="fas"
                    :icon="searchElement.displayDetail ? 'caret-up' :'caret-down'"
                    size="is-medium"
                    @click.native="searchElement.displayDetail = !searchElement.displayDetail"
                />
              </b-tooltip> -->
              <figure v-if="!editResults" class="d-lg-flex d-grid align-items-center m-b-0 delete-result"
                @click="deleteResultModal(searchElement)">
                <button class="btn btn-default">
                  <i class="feather icon-x" :content="$t('modal.deleteResult')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                </button>
                <!-- <b-tooltip class="deleteIcon" :label="this.$t('group.remove_element')">
                  <b-icon
                      icon="times"
                      size="is-medium"
                  />
                </b-tooltip> -->
              </figure>
              <figure class="d-flex justify-content-center align-items-center m-b-0">

                <div class="form-check m-l-10 m-t-10 mt-lg-0" v-show="editResults" :content="$t('result.select_element')" v-tippy="{ placement : 'top',  arrow: true }"><input
                    type="checkbox" v-model="searchElement.selected" class="form-check-input"></div>
              </figure>
              <!-- <b-form-checkbox v-model="searchElement.selected"></b-form-checkbox> -->
              <!-- <b-tooltip v-else class="deleteIcon" :label="this.$t('group.select_element')" style='margin-left: 1rem'>
                <b-checkbox
                    v-model="searchElement.selected"
                >
                </b-checkbox>
              </b-tooltip> -->

            </div>

            <div class="elementDetails" v-show="searchElement.displayDetail">
              <div class="list-group"
                v-if="searchElement.search_result.data  && searchElement.search_result.data.length">

                <InfoBlock v-for="(param, index) in searchElement.search_result.data" :key="index" :dataParam="param"
                  :resultTitle="searchElement.param" :resultSource="searchElement.source" />
              </div>
              <div class="wrapperNoResult" v-else>
                <div class="wrapperHeader">{{searchElement.info_date | moment('DD.MM.YYYY, HH:mm')}}</div>
                <div class="wrapperContent"> {{ $t("result.source") }}: {{searchElement.source}}</div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <b-modal ref="delete-modal" :title=" $t('result.delete_selected')" hide-footer centered>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" block @click="deleteSelectedResult()">{{
        $t('modal.actionDelete') }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="hideModal">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>
    <b-modal ref="rm-modal" :title="$t('result.delete_search_result')" hide-footer centered>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" block @click="removeSelectedItems()">{{
        $t('modal.actionDelete') }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="hideRmModal">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>


    <b-modal centered ref="search-photo-modal" hide-footer  :title="this.$t('photo_fragment.search_params')">
       <span>
          {{ $t("searchOptions.options") }}:
          <span v-for="(item, k) in visualOptions" :key="k" style="display: inline-block; margin-right: 5px;">
              {{ $t(`visualOptions.${item}`) }}{{ k === visualOptions.length - 1 ? '' : ', ' }}
          </span>
      </span>
      <div class="d-grid">
        <b-button class="mt-3" variant="secondary" block @click="searchByPhoto">{{ $t("modal.search") }}</b-button>
        <b-button class="mt-2" variant="danger" block  @click="cancelModal">{{ $t("modal.cancel") }}</b-button>
      </div>
    </b-modal>


  </div>
</template>

<script>
import ImageProfile from "./ImageProfile";
import PinchZoom from "vue-pinch-zoom";
import imageMixin from "../mixins/imageMixin";
import InfoBlock from "./results/InfoBlock";
import imgSearch from "@/mixins/imgSearch";
import filters from "@/mixins/filters";
import SearchedFace from "@/components/results/searchedFace";
import env from "@/configs/env.json";
import _ from "underscore";
import PhotoFragment from "@/components/PhotoFragment.vue";

export default {
  name: "localDatabaseSearchResult",
  props: ['grouped', 'meta', "icon", 'photosrc', 'result'],
  components: {
    PhotoFragment,
    ImageProfile,
    PinchZoom,
    InfoBlock,
    SearchedFace
  },

  mixins: [
    imageMixin, imgSearch, filters
  ],
  data() {
    return {
      searchResults: [],
      isPhotoSegments: false,
      showResults: true,
      editResults: false,
      resultForDelete: {},
      prefix: env.prefix,
      visualOptions: [],
      payload: undefined
    }
  },
  computed: {
    resultsCount(){
      let count = 0;
      this.grouped.forEach(group=>{
        if (group.search_results){
          group.search_results.forEach(result=>{
            if(result.photos) {
              count += result.photos.length
            } else {
              count += 1
            }
          })
        }
      })
      return count
    },

    isIcon() {
      return typeof this.icon !== 'undefined'
    },
    locale() {
      return this.$i18n.locale === 'ua' ? 'uk': this.$i18n.locale
    }
  },
  mounted() {
    this.transformResults();
    // for(const ind in this.searchResults) {
    //   console.log(this.$refs[`searchedFace${ind}`][0]); 
    // }
  },
  watch: {
    src() {
      this.$emit('imgSource', {
        source: this.src
      })
    },
    photosrc(val) {
      if(val) {
        for(const ind in this.searchResults) {
          this.$refs[`searchedFace${ind}`][0].setImageParam(val, this.result); 
        }
      }
    }
  },
  methods: {
    isAnyUrl(el){
      let exist = false;
      el.forEach(item => {
        if (item.param === "social_url"){
          exist = true;
        }
      })
      return exist
    },
    indexOfUrl(el){
      let index;
      el.forEach((item,indx) => {
        if (item.param === "social_url"){
          index = indx;
        }
      })
      return index;
    },

    editMode() {
      this.editResults = !this.editResults;
    },
    selectAll(state) {
      this.searchResults.forEach(result => {
        result.selected = state;
      })
    },
    removeSelectedItems() {
      this.searchResults.forEach(result => {
        if (result.selected) {
          this.showResults = false;
          let payload = {
            meta: this.meta,
            searchElement: result
          }
          this.$store.dispatch('contacts/setRemoveResultLocal', payload).then(() => {
            this.$store.dispatch('contacts/updateResultChild', this.meta.key).then(() => {
              this.$refs['rm-modal'].hide();
              this.transformResults();
              this.showResults = true;
            })
          })
        }
      })
      this.$emit('isLocalResultEmpty')
    },
    hideModal() {
      this.$refs['delete-modal'].hide()
    },
    hideRmModal() {
      this.$refs['rm-modal'].hide()
    },
    searchByPhoto() {
      const formSearch = new FormData();
      formSearch.append('param', 'image');
      this.$toast.success( this.$t("result.search_added_to_main_page") , {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
      this.$t("result.search_added_to_main_page")

      this.$store.dispatch('contacts/searchByPhotoLink', {
        payload: this.payload,
      })
      this.cancelModal();
    },

    cancelModal(){
      this.$refs['search-photo-modal'].hide();
    },

    showModal(el){
      this.payload = {
        param: 'image',
        value: el,
        secondarySearch: true
      }
      let fullAlgorithms = JSON.parse(localStorage.getItem('fullAlgorithms'))
      let fullData = JSON.parse(localStorage.getItem('fullData'))

      let params = Object.assign(fullData[this.payload.param], fullAlgorithms[this.payload.param])

      if (!params.service_types.length){
        delete params.service_types
      }
      this.payload = Object.assign(this.payload, params)

      const keys = Object.keys(params);
      this.visualOptions = [];
      const options = [];
      for(const key of keys) {
        if(typeof params[key] !== 'undefined') {
          options.push(`${key}_${params[key]}`);
        }
      }
      const filteredOptions = options.filter(item => {
        if((!options.includes('offline_false')
            // || !options.includes('service_types_online')
            || !options.includes('service_types_online,hlr')
            || !options.includes('service_types_hlr,online')
            || !options.includes('service_types_face')
            || !options.includes('advanced_true')
            || !options.includes('advanced_false')
        ) && (item !== 'offline_false')) {
          return true;
        }
      })
      this.visualOptions = this.visualOptions.concat(filteredOptions);
      this.$refs['search-photo-modal'].show();
    },

    preview(el) {
      el.isPhotoSegments = !el.isPhotoSegments
    },

    deleteResultModal(selectedResult) {
      this.$refs['delete-modal'].show();
      this.resultForDelete = selectedResult;
    },

    deleteRmModal() {
      this.$refs['rm-modal'].show();
    },

    deleteSelectedResult() {
      let payload = {
        meta: this.meta,
        searchElement: this.resultForDelete
      }
      this.showResults = false;
      this.$store.dispatch('contacts/setRemoveResultLocal', payload).then(() => {
        this.$store.dispatch('contacts/updateResultChild', this.meta.key).then(() => {
          this.transformResults();
          this.showResults = true;
          this.hideModal();
          this.resultForDelete = {};
        })
      })

    },

    zoomImg() {
      this.isPhotoSegments = true;
    },

    makeStringBeautiful: function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    },

    isNamed: function (el) {
      let name;
      if (el.data.length > 0 && Array.isArray(el.data)) {
        el.data.forEach(item => {
          if (item.param && item.param === "name") {
            name = item.value;
          }
        })
      }
      if (typeof name === 'undefined') {
        name = this.$t("result.no_name")
      }
      return name
    },


    onLoadImg() {
      this.$forceUpdate();
    },
    transformResults: function () {
      let tempArr = [];
      this.grouped.forEach(item => {
        if(item.search_results) {
          item.search_results.forEach(innerItem => {
            if(_.isArray(innerItem.photos)) {
              innerItem.photos.forEach(photo => {
                tempArr.push({
                  name: this.isNamed(innerItem),
                  icon: item.icon,
                  param: typeof item.param !== 'undefined' ? item.param : '',
                  displayDetail: false,
                  isPhotoSegments: false,
                  search_result: {
                    data: innerItem.data,
                    info_date: innerItem.info_date,
                    photos: photo,
                  },
                  source: item.source,
                  source_locales: typeof item.source_locales !== 'undefined' ? item.source_locales : undefined,
                  info_date: innerItem.info_date,
                  type: item.type,
                  local: item.local,
                  value: item.value,
                  face_select: item.face_select,
                  selected: false,
                  score: item.score || 0
                })
              })
            }
          })
        }
      })
      this.searchResults = tempArr;
      this.searchResults = tempArr.sort(function (a, b){
        return b.search_result.photos.score - a.search_result.photos.score
      })
      setTimeout(() => {
        for(const ind in this.searchResults) {
          if(!_.isUndefined(this.$refs[`searchedFace${ind}`])) {
            this.$refs[`searchedFace${ind}`][0].setImageParam(this.photosrc, this.result);
          }
        }
      }, 500);
    }

  },
}
</script>
<style>
.contact-controls-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 0.5rem;
}

.contact-controls-buttons button {
  margin-bottom: 0.5rem;
  width: 25%;
  text-transform: none;
}

.contact-controls-buttons button:last-child {
  width: 25%;
}

.editButtonActive{
  color: rgb(64, 153, 255);
}
.is-24x24 {
  width: 24px;
}

.is-24x24 img {
  width: 24px;
}

.is-48x48 {
  width: 48px;
}

.is-48x48 img {
  width: 48px;
}

.align-items-center {
  align-items: center;
}
.result-image-wrapper {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .result-image-wrapper {
    width: 100%;
    margin-bottom: 5px;
  }

  .result-image-wrapper .imageContainer,
  .result-image-wrapper .photoWrapper {
    width: calc((100% - 53px)/2);
  }

  .searchResults .list-group-item {
    padding-left: 5px;
    padding-right: 5px;
  }
}


.result-image-wrapper img {
  display: inline-block;
}

.delete-result i {
  cursor: pointer;
}
.link-text {
  line-height: 20px;
  vertical-align: bottom;
}
.link-text>span {
  line-height: 20px;
  vertical-align: top;
}
.link-text a{
  display: initial;
  width: calc(100% - 150px);
  overflow-x: hidden;
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  line-height: 20px;
  vertical-align: bottom;
}

.list-group-item > div.elementPreview {
  padding: 0;
}

/*.list-group-item:not(:last-child) > div {*/
/*  border: 0;*/
/*}*/

.score-photo {
  display: block;
  padding: 0 5px;
}

.score-photo i {
  display: block;
  text-align: center;
  font-size: 30px;
}
.score-photo span {
  cursor: default;
}

.elementDetails .list-group {
  width: 100%;
}

.score-photo {
  width: 53px;
}

@media(min-width: 993px) {
  .photo-result-text {
    max-width: calc(100% - 365px);
  }
}

/* .searchResults .list-group-item {
    padding-left: 10px;
  } */
</style>
